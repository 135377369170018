.smooth-alert-wrapper.fixed{
  position: fixed;
  z-index: 200;
  width: 300px;
  max-width: 100%;
  left: calc(50% - 150px);
  top: 30%;
}
.smooth-alert{
  position: relative;
  padding: 1rem 1rem 1rem 3rem;
  margin: 0 0 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.smooth-alert:before{
  position: absolute;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  top: 0.5rem;
  left: 0.5rem;
}

.smooth-alert.error{
  background: #f8d7da;
  border-left: 3px solid #f5c6cb;
  color: #721c24;
}
.smooth-alert.error:before{
  content: '\f188';
}

.smooth-alert.information{
  background: #A9DBF0;
  border-left: 3px solid #007bff;
  color: #0c5460;
}
.smooth-alert.information:before{
  content: '\f05a';
}

.smooth-alert.success{
  background: #d4edda;
  border-left: 3px solid #c3e6cb;
  color: #155724;
}
.smooth-alert.success:before{
  content: '\f058';
}

.smooth-alert.warning{
  background: #fff3cd;
  border-left: 3px solid #ffeeba;
  color: #856404;
}
.smooth-alert.warning:before{
  content: '\f071';
}

.smooth-alert.action{
  background: #FFED6B;
  border-left: 3px solid #ffc107;
}
.smooth-alert.action:before{
  content: '\f12a';
}
