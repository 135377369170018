h1{
  font-weight: 700;
  font-size:2rem;
}

h2{
  font-weight: 700;
  font-size:1.5rem;
}

h3{
  font-size: 1.2rem;
}

h3.in-fieldset{
  text-decoration: underline;
  margin: 0 0 20px 0;
}
