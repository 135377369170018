/*********************************************
OVERRIDE DE HCOFFCANVAS
 */
.hc-offcanvas-nav .nav-close a:hover{
  color: #FFFFFF;
}

.hc-nav-trigger.hc-nav-1 {
  right: 10px;
  top: unset;
}
.hc-nav-trigger span,
.hc-nav-trigger span:before,
.hc-nav-trigger span:after{
  background: $redColor;
}
.hc-offcanvas-nav *{
  border-color: $redColor!important;
  background: white!important;;
  color: black!important;
}
.hc-offcanvas-nav .nav-close span:before,
.hc-offcanvas-nav .nav-close span:after{
  border-color: $redColor!important;
}