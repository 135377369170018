footer {
  padding: 2rem 0;
  background: $redColor;

  * {
    color: #ffffff;
  }

  a:hover{
    color: $txtColorWhite;
  }


  section.top_footer {
    display: flex;
    justify-content: space-between;
    @media (max-width: $deviceMobileBreakpointMax) {
      flex-direction: column;
    }
    >div {
      width: 33.33%;
      padding: 0 1rem;
      @media (max-width: $deviceMobileBreakpointMax) {
        width: 100%;
        padding: 0;
      }
    }

    .text-with-picto{
      display: flex;
      margin: 0 0 1rem 0;
      .picto{
        margin: 0 1.5rem 0 0;
      }
      .days{
        font-size: 0.857rem;
      }
    }

    h3 {
      margin: 0 0 1rem 0;
      font-size: 1.428rem;
      font-family: $fontRobotoMedium, sans-serif;
    }


    .contact {
      padding: 2.5rem 0 0 0;
      @media (max-width: $deviceMobileBreakpointMax) {
        margin: 0 0 2rem 0;
        padding: 0;
      }
    }

    .info{
      @media (max-width: $deviceMobileBreakpointMax) {
        margin: 0 0 2rem 0;
      }
      .links{
        border-left: 1px solid #ffffff;
        padding: 0 0 0 1rem;

        a{
          display: block;
          margin: 0 0 0.5rem 0;
          font-size: 1.143rem;
          font-family: $fontRobotoMedium, sans-serif;
          font-weight: 500;

          @media (max-width: $deviceMobileBreakpointMax) {
            font-size: 1rem;
            font-family: $fontRobotoRegular, sans-serif;
            font-weight: 400;
            margin: 1rem 0 1rem 0;
          }
        }
      }
    }

    .channels {
      .links{
        a{
          display: inline-block;
          margin: 0 2rem .5rem 0;
          width: 39px;
          @media (max-width: $deviceMobileBreakpointMax) {
            width: 35px;
            margin: 1rem 2rem 0 0;
          }
          img{
            width: 100%;
          }
        }
      }
    }
  }

  section.sub_footer {
    margin: 2rem 0 0 0;
    display: flex;
    justify-content: center;

    @media (max-width: $deviceMobileBreakpointMax) {
      flex-direction: column;
    }
    a,
    span{
      display: inline-block;
      margin: 0 2rem;
      font-size: 1.143rem;
      font-family: $fontRobotoMedium, sans-serif;
      font-weight: 500;
      @media (max-width: $deviceMobileBreakpointMax) {
        margin: 0 0 1rem 0;
        font-size: 1rem;
        font-family: $fontRobotoRegular, sans-serif;
        font-weight: 400;
      }
    }
  }
}