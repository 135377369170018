$main_color: #2E4F78;
$main_color_lighter: #2b7df6;

$txt_color_light: #FFFFFF;
$txt_color_dark: #000000;
$txtColorWhite: #FFFFFF;
$txtColorDark: #000000;
$txtColorBlue: #2E4F78;

$redColor: #c0151c;
$yellowColor: #FEB51C;
$errorColor: red;
$validColor: green;
$updated: yellow;

$font_size_base: 14px;
$fontRobotoRegular: 'robotoregular';
$fontRobotoMedium: 'robotomedium';

$deviceMobileBreakpointMax: 767px;
$deviceMobileBreakpointMin: 768px;

$deviceSmallBreakpointMax: 1279px;
$deviceSmallBreakpointMin: 1280px;

//
//$grid-breakpoints: (
//        xs: 0,
//        sm: 576px,
//        md: 768px,
//        lg: 992px,
//        xl: 1200px
//) !default;


