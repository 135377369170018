#register {
  .documents {

    .steps {

      color: $redColor;
      font-size: 1.5rem;
      margin: 10px 0;
      .step_red {
        background: $redColor;
        color: white;
        padding: 10px 15px;
        margin: 0 10px 0 0;
        border-radius: 50%;
      }
    }
    .vignette {
      .content {
        margin-top: 10px;
        margin-bottom: 10px;
        background: $redColor;
        text-align: center;

        a {
          color: white;
          padding: 20px;
          display: block;
          position: relative;

          i {
            position: absolute;
            left: 10px;
            font-size: 30px;
            top: 50%;
            transform: translate(0, -50%)
          }
        }

        a:hover {
          text-decoration: none;
        }
      }
    }
  }
}