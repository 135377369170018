
#actualities {
  .actualites {
    .actualite {
      .content {
        height: 100%;
        box-shadow: 0 3px 6px $redColor;
        .titre_actualite {
          h5 {
            font-size: 1rem !important;
            text-align: center;
            color: $redColor;
            min-height: 40px;
          }
        }
        .actualite_content{
          padding: 10px;
        }
      }
    }
  }
}
#actuality {
  .content {
    img {
      max-width: 100% !important;
    }
  }
}