.password-seeable{
  position: relative;

  i{
    position: absolute;
    z-index: 2;
    right: 10px;
    top: 37px;
    font-size:1.02vw;
    font-weight: 900;
    margin:0 10px 0 0;
    cursor: pointer;
  }
}
