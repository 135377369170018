.rgpd-banner {
    display: none;
    position: fixed;
    z-index: 99999;
    width: 96vw;
    max-height: 96vh;
    margin: 0 auto;
    bottom: 2vh;
    left: 2vw;
    background: #FFFFFF;
    box-shadow: 0px 3px 30px #0000004D;
    border-radius: 20px 0px 20px 20px;
    padding: 15px;
    overflow: auto;
}

.rgpd-banner .title{
    margin: 0 0 20px 0;
}
.rgpd-banner .infos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 2rem 0;
}
@media (max-width:1199px){
    .rgpd-banner .infos {
        flex-direction: column;
    }
}

.rgpd-banner .links {
    flex-shrink: 0;
    text-align: center;
}
.rgpd-banner .links a{
    margin: 0 0.5rem 1rem 0.5rem;
}

.rgpd-banner .modules {
    display: none;
}
.rgpd-banner .modules .back {
    margin: 0 0 1rem 0;
}

.rgpd-banner .modules .module{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 2rem 0;
}
.rgpd-banner .modules .module .description .name{
    text-transform: uppercase;
    margin: 0 0 1rem 0;
    font-weight: bold;
}
.rgpd-banner .modules .module .description .text{
    padding: 0 2rem 0 0;
}

.rgpd-banner .modules .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.rgpd-banner .modules .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.rgpd-banner .modules .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.rgpd-banner .modules .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.rgpd-banner .modules input:checked + .slider {
    background-color: #7FE293;
}

.rgpd-banner .modules input:focus + .slider {
    box-shadow: 0 0 1px #7FE293;
}

.rgpd-banner .modules input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.rgpd-banner .modules .slider.round {
    border-radius: 34px;
}

.rgpd-banner .modules .slider.round:before {
    border-radius: 50%;
}


.rgpd-banner .modules .modules-submit{
    text-align: center;
}