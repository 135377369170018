/*********************************************************************
FIL D'ARIANNE
*********************************************************************/
#breadcrumb{
  padding:0 0 0 120px;
  height:40px;
  line-height: 40px;
  background: $redColor;

  ul {
    padding:0;
    margin:0;
    display: flex;
    align-items: center;
    li{
      list-style:none;
      display: flex;
      align-items: center;

      a{
        display: inline-block;
        padding:0 5px;
        font-size:1rem;
        color: black;
      }

      a:hover{
        text-decoration:none;
      }

      a.last{
        color: white;
      }
    }

    li::before {
      content: none!important;
    }

    li:last-child a{
      text-decoration:none;
    }
  }
}
