#products {
  background: $redColor;
  color: white;
  position: relative;

  .vignette {
    .content {
      position: relative;
      background: #ffffff;
      color: black;

      h2 {
        top: 0;
        right: 0;
        left: 0;
        text-align: center;
        padding-top: 10px;
        font-size: 1.2rem;
      }

      .description {
        padding: 0 10px 10px;
        min-height: 130px

      }
    }
  }
}

#products:before {
  content: '';
  display: block;
  width: 50%;
  height: 0;
  border-style: solid;
  border-width: 50px 49vw 0 0;
  border-color: transparent $redColor transparent transparent;
  position: absolute;
  right: 0;
  rotate: 180deg;
  top: 100%;
  background: #ECF0F5;
}

#products:after {
  content: '';
  display: block;
  width: 50%;
  height: 0;
  border-style: solid;
  border-width: 50px 0 0 50vw;
  border-color: transparent transparent transparent $redColor;
  position: absolute;
  left: 0;
  rotate: 180deg;
  top: 100%;
  background: #ECF0F5;
}

#qsn {
  background: #ECF0F5;
  h1{
    font-size: 1.5rem;
  }
  .bottom {
    position: relative;

    .right {
      margin-left: auto;
      padding: 0;

      img {
        width: 100%;
      }
    }
  }

  .left {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-80%, -50%);
    z-index: 9;
    background: #ffffff;
    padding: 3%;
    box-shadow: 0 3px 3px #c0151c;

    @media (max-width: $deviceMobileBreakpointMax) {
      position: relative;
      transform: none;
      left: 0;
      top: 0;
    }
  }
}

#utiles {
  .event {
    background: #c0151c;

    h2 {
      color: white!important;
    }

    .vignette {
      display: flex;
      flex-wrap: wrap;

      .image {
        width: 60%;

        @media (max-width: $deviceMobileBreakpointMax) {
          width: 100%
        }

        img {
          width: 100%;
        }
      }

      .content {
        width: 40%;
        padding: 2% 5% 0;
        color: white!important;

        ul {
          list-style: none !important;
          padding-left: 5px;

          li {
            padding: 5px 0 !important;
            position: relative;
          }

          li::before {
            content: "•" !important;
            color: white !important;
            display: inline-block !important;
            width: 0.5em !important;
            margin-left: -0.5em !important;
            font-size: 25px;
            position: absolute;
            top: -2px;
          }
        }
        @media (max-width: $deviceMobileBreakpointMax) {
          width: 100%
        }

        h3 {
          color: white!important;
          font-weight: bold !important;
        }
      }
    }
  }

  .liens{
    h2{
      font-weight: normal!important;
    }
    text-align: center;
    .content{
      border: solid 1px red;
      position: relative;
      .titre{
        display: none;
        h3{
          width: 100%;
          color: white;
          font-weight: bold;
        }
      }
    }
    .content:hover .titre{
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      background: rgba(192, 21, 28, .9);
    }
  }
}