header {
  position: fixed;
  background: transparent;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  color: white;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  transition: all 2s;

  .top {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;

    .logo {
      width: 20%;

      img {
        width: 40%;
        opacity: 0;
        transition: all 4s;

        @media (max-width: $deviceMobileBreakpointMax) {
          transition: none;
          width: 100%;
          opacity: 1;
        }
      }
    }

    .contact {
      width: 20%;
      @media (max-width: $deviceMobileBreakpointMax) {
        position: absolute;
        right: 10%;
      }
    }

    #menu-site {
      width: 50%;
      margin-right: 10%;

      ul {
        display: flex;
        list-style: none;
        justify-content: space-between;

        li {
          position: relative;

          a {
            color: white;
            text-decoration: none;
            font-size: 22px;
          }
        }

        li:hover:after {
          content: '';
          display: block;
          width: 100%;
          height: 3px;
          background: $redColor;
          position: absolute;
        }

      }
    }
  }
}

header.navShadow {
  background: rgba(255, 255, 255, .9)
}

header.navShadow a,
header.navShadow p {
  color: $redColor !important;
}

header.navShadow .logo img {
  opacity: 1;
}

header.navShadow a.btn {
  color: white !important;
}


.page_header {
  position: relative;

  .logo {
    position: absolute;
    left: 60vw;
    top: 45vh;
    transition: all 2s;
    opacity: 1;
    @media (max-width: $deviceMobileBreakpointMax) {
      left: 20vw;
    }

    img {
      width: 100%;
      transition: all 2s;
    }
  }
}
.page_header.no_header:before,
.page_header.no_header:after{
  content: none
}

.page_header.navShadow {
  .logo {
    left: 0;
    z-index: 9999;
    opacity: 0;

    img {
      width: 46%;
    }

  }
}

#home .page_header::before {
  content: '';
  display: block;
  width: 50%;
  height: 0;
  border-style: solid;
  border-width: 50px 49vw 0 0;
  border-color: transparent $redColor transparent transparent;
  bottom: 0;
  position: absolute;
}

#home .page_header::after {
  content: '';
  display: block;
  width: 50%;
  height: 0;
  border-style: solid;
  border-width: 50px 0 0 49vw;
  border-color: transparent transparent transparent $redColor;
  position: absolute;
  bottom: 0;
  right: 0;
}

#menu-site a, footer .links a{
  cursor: pointer;
}
#menu-site a:hover,
footer .links a:hover{
  text-decoration: none;
}