
.preview_front_box {
  box-shadow: 0 0 10px #999;
  background: rgba(0, 0, 0, .8);
  position: fixed;
  z-index: 9999;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
}

.preview_front_box .preview_box {
  background: #fff;
  display: block;
  width: 90vw;
  margin: auto;
}

.preview_front_box img {
  padding: 0;
  margin: 0;
}

#close_preview {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  background: white;
}

#close_preview:hover {
  background: #c82333;
  color: #fff;
  cursor: pointer;
}

#pdf {
  .vignette .content{
    font-size: 24px;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    text-align: center;
    box-shadow: 0 0 10px 0 rgba(0,0,0, 0.15);
  }
  .vignette .content:hover{
    cursor: pointer;
    box-shadow: 0 0 30px 0 rgba(0,0,0, 0.15);
    background: #c0151c!important;
    color: white!important;

  }
}